import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/(home)": [~7],
		"/book/[slug]": [~21],
		"/manage/hb/[slug]": [~24,[],[6]],
		"/manage/[slug]": [~22,[],[6]],
		"/manage/[slug]/payment": [~23,[],[6]],
		"/pages/contact": [~25],
		"/pages/privacy-policy": [~26],
		"/pages/terms-of-use": [~27],
		"/pages/ue": [~28],
		"/[stays=stays_prefix]": [~16,[5]],
		"/[help=help_prefix]": [~13,[3]],
		"/[bundles=bundles_prefix]": [~9,[2]],
		"/[bundles=bundles_prefix]/book": [10,[2]],
		"/[bundles=bundles_prefix]/compose": [~11,[2]],
		"/[stays=stays_prefix]/detail/[slug]": [~18,[5]],
		"/[stays=stays_prefix]/detail/[slug]/book": [~19,[5]],
		"/[stays=stays_prefix]/icsp/book": [20,[5]],
		"/[help=help_prefix]/[category]": [~14,[3]],
		"/[help=help_prefix]/[category]/[question]": [~15,[3]],
		"/[flights=flights_prefix]/[...params]": [~12],
		"/[stays=stays_prefix]/[...params]": [~17,[5]],
		"/(landing)/[...slug]": [~8]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';