import * as Sentry from "@sentry/sveltekit";
import {
    ReportingObserver as ReportingObserverIntegration,
    HttpClient as HttpClientIntegration,
    ExtraErrorData as ExtraErrorDataIntegration
} from "@sentry/integrations";

import { dev } from '$app/environment';
import posthog from 'posthog-js';
import { dsn } from '$lib/sentry';

const runningLocally = window.location.href.includes('localhost') ||
    window.location.href.includes('127.0.0.1');

if (!dev) {
    Sentry.init({
        dsn,
        integrations: [
            new ExtraErrorDataIntegration({ depth: 3 }),
            new HttpClientIntegration({
                failedRequestStatusCodes: [[400, 505], 507],
            }),
            new posthog.SentryIntegration(posthog, 'ticketwhat2', 4505257040281600),
        ],
        sendDefaultPii: true,
        beforeSend(event, hint) {
            if (runningLocally) {
                console.warn('Report to Sentry when running locally: ', { event, hint });
                return null;
            }
            return event;
        }
    });

    if (!document.cookie.includes('gark')) {
        posthog.init('phc_9acooBo0usrRR6D5O85tqCu5s4JWVouEeVsvBkvMMK4', {
            api_host: 'https://app.posthog.com'
        });
    }
} else console.debug("sentry reporting disabled on localhost");

export const handleError = !dev ? Sentry.handleErrorWithSentry() : undefined;
